import {
    API_ENDPOINTS,
    BottomAnchoredControls,
    Button,
    ButtonTypes,
    Card,
    CardBody,
    CardData,
    customToast,
    FormInput,
    getThemeFromUrl,
    ImageType,
    PageLayout,
    PhoneNumberField,
    PostcodeLookup,
    replaceKeyWithValue,
    Roles,
    route,
    SelectableCardInput,
    SelectionCardsGroup,
    SelectOption,
    Sizes,
    Spinner,
    StringHelpers,
    SubTitle,
    Themes,
    useDisabledContext,
    useForm,
    useLangContext,
    useQueries,
    useScrollToError,
    useStore,
    useUserContext,
    Variants,
    WidthConstrainedContainer,
    GasSafeLookup,
} from 'carrier-fe';
import React, {useEffect, useLayoutEffect, useMemo, useReducer, useState} from 'react';
import {replace, useNavigate, useParams} from 'react-router-dom';
import {CompanyStorePayload} from '../../../types/companies';
import axios from 'axios';
import {CompanyTypes, SYSTEM_TYPES} from '../../../Constants';
import {defaultData, handleError, initialSelectsState, selectsReducer} from './company-helpers';
import ViessmannGas from 'carrier-fe/src/images/viessmann_gas.jpg';
import ViessmannHeatpumps from 'carrier-fe/src/images/viessmann_heatpumps.jpg';
import ViessmannElectric from 'carrier-fe/src/images/viessmann_electric.svg';
import ViessmannSolar from 'carrier-fe/src/images/viessmann_solar.jpg';

const ColumnLayout = ({children}: { children: React.ReactNode }) => {
    return (
        <div className="col-12 col-md-6">
            <div>{children}</div>
        </div>
    );
};

function CompanyForm() {
    // Hooks and Contexts
    const {companyId} = useParams();
    const {user} = useUserContext();
    const {crud, fields} = useLangContext();
    const {disabled} = useDisabledContext();
    const navigate = useNavigate();

    // State Management
    const {store: loaders, setValues: setLoaders} = useStore({
        fetchingGasSafe: false,
        fetchingCompany: false,
    });
    const [selects, dispatchSelects] = useReducer(selectsReducer, initialSelectsState);

    // Queries
    const {data, isLoading} = useQueries([
        {
            key: 'companies',
            config: {
                method: 'GET',
                url: route(
                    API_ENDPOINTS.GENERIC.SELECT.COMPANIES + `?type=${CompanyTypes.DISTRIBUTOR}`
                ),
            },
        },
        {
            key: 'distributor_claim_types',
            config: {
                method: 'GET',
                url: route(API_ENDPOINTS.GENERIC.SELECT.DISTRIBUTOR_CLAIM_TYPES),
            },
        },
        {
            key: 'roles',
            config: {
                method: 'GET',
                url: route(
                    API_ENDPOINTS.GENERIC.SELECT.COMPANY_TYPE_ROLES + `?type=${CompanyTypes.INSTALLER}` // By default fetch installer roles
                ),
            },
        },
    ]);

    // Derived States
    const isEdit = Boolean(companyId);
    const componentLoading = useMemo(
        () => isLoading || loaders?.fetchingCompany,
        [isLoading, loaders?.fetchingCompany]
    );
    const booleanOptions = useMemo(
        () =>
            crud?.options?.boolean?.map((option: SelectOption) => ({
                ...option,
                value: Number(option.value) === 1,
            })),
        [crud?.options?.boolean]
    );
    const title = useMemo(
        () =>
            isEdit
                ? replaceKeyWithValue(
                    crud?.pages?.edit.title || 'Edit :model',
                    'model',
                    crud?.models?.company || 'Company'
                )
                : replaceKeyWithValue(
                    crud?.pages?.create.title || 'Create :model',
                    'model',
                    crud?.models?.company || 'Company'
                ),
        [crud, isEdit]
    );
    const themes = useMemo(() => {
        return {
            isToshiba: getThemeFromUrl() === Themes.Toshiba,
            isViessmann: getThemeFromUrl() === Themes.Viessmann,
        };
    }, []);

    // Handlers
    const handleSubmit = async (data: Partial<CompanyStorePayload>): Promise<any> => {
        // remove dynamic fields
        if(!data?.gas_boiler_systems_enabled){
            delete data?.['gas_safe_licence_card_number']
            delete data?.['gas_safe_confirmed']
            delete data?.['gas_safe_number']
            delete data?.['user_gas_safe_confirmed']
        }
        if(!data?.solar_systems_enabled && !data?.heat_pump_systems_enabled){
            delete data?.['mcs_number']
        }
        if(data?.sap_number) {
            data['heat_pump_systems_enabled'] = true
            data['solar_systems_enabled'] = true
            data['other_systems_enabled'] = true
        }

        return isEdit
            ? axios.patch(
                route(API_ENDPOINTS.ADMIN.COMPANY.UPDATE, {companyId: companyId || ''}),
                data
            )
            : axios.post(route(API_ENDPOINTS.ADMIN.COMPANY.STORE), data);
    };

    // Form Initialization
    const form = useForm(handleSubmit, defaultData);
    const {Submit, isSuccess, data: submissionData, errors} = form;
    const formRef = useScrollToError(form?.errors);

    // Effects
    useLayoutEffect(() => {
        if (companyId) loadCompanyData();
        // if distribution manager, set type to distributor
        if (user?.role_name === Roles.DISTRIBUTION_MANAGER) {
            form?.setValues({type: CompanyTypes.DISTRIBUTOR});
        } else {
            form?.setValues({type: CompanyTypes.INSTALLER});
        }
    }, [companyId]);

    useEffect(() => {
        if (data) updateSelectOptions(data);
    }, [data]);

    useEffect(() => {
        if (isSuccess) navigate(`/companies/${submissionData?.id}`);
    }, [isSuccess]);

    // misc
    const loadCompanyData = async () => {
        setLoaders({fetchingCompany: true});
        try {
            const response = await axios.get(
                route(API_ENDPOINTS.ADMIN.COMPANY.EDIT, {companyId: companyId || ''})
            );
            form?.setValues({...response?.data?.data, role_id: user?.role_id});

            await fetchRoles(response?.data?.data?.type);
        } catch (error: any) {
            handleError(error);
        } finally {
            setLoaders({fetchingCompany: false});
        }
    };

    const updateSelectOptions = (data: any) => {
        dispatchSelects({
            type: 'SET_DISTRIBUTOR_CLAIM_TYPES',
            payload: data?.distributor_claim_types?.data || [],
        });
        dispatchSelects({type: 'SET_ROLES', payload: data?.roles?.data || []});
        dispatchSelects({type: 'SET_COMPANIES', payload: data?.companies?.data || []});
    };

    const fetchRoles = async (type: string) => {
        if (type) {
            try {
                const response = await axios.get(
                    route(API_ENDPOINTS.GENERIC.SELECT.COMPANY_TYPE_ROLES + `?type=${type}`)
                );
                
                dispatchSelects({type: 'SET_ROLES', payload: response?.data?.data || []});
            } catch (error: any) {
                handleError(error);
            }
        }
    };

    const renderSystemTypes = () => {
        let errorsPresent = false;
        if (!!errors) {
            if (
                (!!errors.gas_boiler_systems_enabled || !!errors.heat_pump_systems_enabled || !!errors.solar_systems_enabled || !!errors.other_systems_enabled) &&
                (!form?.store?.gas_boiler_systems_enabled && !form?.store?.heat_pump_systems_enabled && !form?.store?.solar_systems_enabled && !form?.store?.other_systems_enabled)
            ) {
                errorsPresent = true;
            }
        }

        return (
            <div className="d-flex gap-4 mb-4">
                <SelectableCardInput
                    className="m-0"
                    value={form?.store?.gas_boiler_systems_enabled}
                    imageSrc={ViessmannGas}
                    label={StringHelpers.title(
                        fields?.company_gas_boiler_systems_enabled
                    )}
                    isSelected={form?.store?.gas_boiler_systems_enabled}
                    onChange={(value: any) => {
                        form?.setValues({
                            gas_boiler_systems_enabled: !value,
                        });
                    }}
                    errorField={errorsPresent}
                />
                <SelectableCardInput
                    className="m-0"
                    value={form?.store?.heat_pump_systems_enabled}
                    imageSrc={ViessmannHeatpumps}
                    label={StringHelpers.title(
                        fields?.company_heat_pump_systems_enabled
                    )}
                    isSelected={form?.store?.heat_pump_systems_enabled}
                    onChange={(value: any) => {
                        form?.setValues({
                            heat_pump_systems_enabled: !value,
                        });
                    }}
                    errorField={errorsPresent}
                />
                <SelectableCardInput
                    className="m-0"
                    value={form?.store?.solar_systems_enabled}
                    imageSrc={ViessmannSolar}
                    label={StringHelpers.title(
                        fields?.company_solar_systems_enabled
                    )}
                    isSelected={form?.store?.solar_systems_enabled}
                    onChange={(value: any) => {
                        form?.setValues({
                            solar_systems_enabled: !value,
                        });
                    }}
                    errorField={errorsPresent}
                />
                <SelectableCardInput
                    className="m-0"
                    value={form?.store?.other_systems_enabled}
                    imageSrc={ViessmannElectric}
                    label={StringHelpers.title(
                        fields?.company_other_systems_enabled
                    )}
                    isSelected={form?.store?.other_systems_enabled}
                    onChange={(value: any) => {
                        form?.setValues({
                            other_systems_enabled: !value,
                        });
                    }}
                    errorField={errorsPresent}
                />
            </div>
        )
    }

    const renderToshibaInstallerFields = () => {
        return (
            <>
                <div className="row">
                    <ColumnLayout>
                        {form.Input({
                            name: 'fgas_refcom_standard_number',
                            label: StringHelpers.title(
                                fields?.fgas_refcom_standard_number ||
                                'FGAS / REFCOM standard number'
                            ),
                            type: 'text',
                        })}
                    </ColumnLayout>
                    <ColumnLayout>
                        {form.Input({
                            name: 'refcom_elite_number',
                            label: StringHelpers.title(
                                fields?.refcom_elite_number || 'REFCOM elite number'
                            ),
                            type: 'text',
                        })}
                    </ColumnLayout>
                </div>
                {form.Input({
                    name: 'account_number',
                    label: StringHelpers.title(
                        fields?.account_number || 'Account number'
                    ),
                    type: 'text',
                })}
            </>
        );
    }

    const renderToshibaDistributorFields = () => {
        return (
            <>
                {form.Input({
                    name: 'dispatch_note_field_label',
                    label: StringHelpers.title(
                        fields?.dispatch_note_field_label ||
                        'Dispatch note field label'
                    ),
                    type: 'text',
                })}
                {form.Input({
                    name: 'distributor_claim_type',
                    label: StringHelpers.title(
                        fields?.distributor_claim_type || 'Distributor claim type'
                    ),
                    type: 'select',
                    placeholder: 'Select',
                    options: selects?.distributor_claim_types,
                })}
                {form.Input({
                    name: 'distributor_claim_inbox_email',
                    label: StringHelpers.title(
                        fields?.distributor_claim_inbox_email ||
                        'Distributor claim inbox email'
                    ),
                    type: 'text',
                })}
                <FormInput
                    type="select"
                    name="dispatch_note_required"
                    label={StringHelpers.title(
                        fields?.dispatch_note_required || 'Dispatch note required'
                    )}
                    placeholder={StringHelpers.title(
                        replaceKeyWithValue(crud?.placeholders?.select, 'model', '')
                    )}
                    value={form?.store?.dispatch_note_required}
                    onChange={(v) =>
                        form?.setValues({
                            dispatch_note_required:
                                typeof v === 'string' ? JSON.parse(v) : v,
                        })
                    }
                    options={booleanOptions}
                />
            </>
        )
    }

    const renderGasSafeFields = () => {
        return (
            <>
                <SubTitle
                    title={
                        (crud?.models?.company || 'Company') +
                        ' ' + (crud?.sub_titles?.gas_safe || 'GasSafe') +
                        ' ' + (crud?.sub_titles?.details || 'Details')
                    }
                    className="my-3 w-100"
                    style={{color: '#464C5E'}}
                />
                <GasSafeLookup
                    fetchOnLoad={isEdit}
                    displayCard
                    onGasSafeNumberChange={(value) => {
                        form?.setValues({
                            gas_safe_number: value,
                        });
                    }}
                    onGasSafeConfirmedChange={(value) => {
                        form?.setValues({
                            gas_safe_confirmed: value,
                        });
                    }}
                    disabled={disabled}
                    gasSafeNumber={form?.store?.gas_safe_number}
                    gasSafeConfirmed={form?.store?.gas_safe_confirmed}
                    labels={{
                        gasSafeNumber: fields?.gas_safe_number,
                        gasSafeConfirmed: fields?.gas_safe_confirmation_generic,
                    }}
                    errors={{
                        gasSafeNumber: form?.errors?.gas_safe_number,
                        gasSafeConfirmed: form?.errors?.gas_safe_confirmed,
                    }}
                    type={'company'}
                />
            </>
        )
    }

    const renderPrimaryUserFields = () => {
        return (
            <Card>
                <CardBody>
                    <SubTitle
                        className="mb-3"
                        style={{color: '#464C5E'}}
                        title={StringHelpers.title(
                            `${crud?.sub_titles?.primary} ${crud?.models?.user}`
                        )}
                    />
                    <div className="row">
                        <ColumnLayout>
                            {form.Input({
                                name: 'first_name',
                                label: StringHelpers.title(
                                    fields?.first_name || 'First Name'
                                ),
                                type: 'text',
                            })}
                        </ColumnLayout>
                        <ColumnLayout>
                            {form.Input({
                                name: 'last_name',
                                label: StringHelpers.title(fields?.last_name || 'Last Name'),
                                type: 'text',
                            })}
                        </ColumnLayout>
                    </div>
                    <div className="row">
                        <ColumnLayout>
                            {form.Input({
                                name: 'email',
                                label: StringHelpers.title(fields?.email || 'Email'),
                                type: 'email',
                            })}
                        </ColumnLayout>
                        <ColumnLayout>
                            <FormInput
                                type="text"
                                label={StringHelpers.title(fields?.username || 'Username')}
                                name="username"
                                value={form?.store?.email}
                                disabled
                            />
                        </ColumnLayout>
                    </div>

                    <div className="col-12 mb-3">
                        <PhoneNumberField
                            noMargin
                            label={StringHelpers.title(
                                fields.mobile_phone_number || 'Mobile number'
                            )}
                            name="mobile_phone_number"
                            value={form?.store?.mobile_phone_number}
                            onChange={(value) =>
                                form.setValues({
                                    mobile_phone_number: value,
                                })
                            }
                            disabled={disabled}
                            errorMessages={form?.errors?.mobile_phone_number}
                            prefixValue={form?.store?.mobile_phone_prefix}
                            prefixOnChange={(value) =>
                                form.setValues({
                                    mobile_phone_prefix: value,
                                })
                            }
                        />
                    </div>

                    {/* Landline Details */}
                    <div className="col-12 mb-5">
                        <PhoneNumberField
                            noMargin
                            label={StringHelpers.title(
                                fields.landline_phone_number || 'Landline number'
                            )}
                            name="landline_phone_number"
                            value={form?.store?.landline_phone_number}
                            onChange={(value) =>
                                form.setValues({
                                    landline_phone_number: value,
                                })
                            }
                            disabled={disabled}
                            errorMessages={form?.errors?.landline_phone_number}
                            prefixValue={form?.store?.landline_phone_prefix}
                            prefixOnChange={(value) =>
                                form.setValues({
                                    landline_phone_prefix: value,
                                })
                            }
                        />
                    </div>
                    <PostcodeLookup
                        data={{
                            address_id: form?.store?.user_address_id,
                            address_line_1: form?.store?.user_address_line_1,
                            address_line_2: form?.store?.user_address_line_2,
                            address_line_3: form?.store?.user_address_line_3,
                            town_city: form?.store?.user_town_city,
                            state_county: form?.store?.user_state_county,
                            postcode_zipcode: form?.store?.user_postcode_zipcode,
                            local_authority_code: form?.store?.user_local_authority_code,
                            sub_country_name: form?.store?.user_sub_country_name,
                            country_code_iso_3: form?.store?.user_country_code_iso_3,
                        }}
                        setData={(value) => {
                            form.setValues({
                                user_address_id: value?.address_id,
                                user_address_line_1: value?.address_line_1,
                                user_address_line_2: value?.address_line_2,
                                user_address_line_3: value?.address_line_3,
                                user_town_city: value?.town_city,
                                user_state_county: value?.state_county,
                                user_postcode_zipcode: value?.postcode_zipcode,
                                user_local_authority_code: value?.local_authority_code,
                                user_sub_country_name: value?.sub_country_name,
                                user_country_code_iso_3: value?.country_code_iso_3,
                            });
                        }}
                        errors={form?.errors}
                    />
                    {form.store.gas_boiler_systems_enabled && (<>
                        <SubTitle
                            title={
                                (crud?.models?.user || 'User') +
                                ' ' + (crud?.sub_titles?.gas_safe || 'GasSafe') +
                                ' ' + (crud?.sub_titles?.details || 'Details')
                            }
                            className="my-3 mt-5 w-100"
                            style={{color: '#464C5E'}}
                        />
                        <GasSafeLookup
                            displayCard
                            onGasSafeNumberChange={(value) => {
                                form?.setValues({
                                    gas_safe_licence_card_number: value,
                                });
                            }}
                            onGasSafeConfirmedChange={(value) => {
                                form?.setValues({
                                    user_gas_safe_confirmed: value,
                                });
                            }}
                            disabled={disabled}
                            gasSafeNumber={form?.store?.gas_safe_licence_card_number}
                            gasSafeConfirmed={form?.store?.user_gas_safe_confirmed}
                            labels={{
                                gasSafeNumber: fields?.gas_safe_licence_card_number,
                                gasSafeConfirmed: fields?.gas_safe_confirmation_generic,
                            }}
                            errors={{
                                gasSafeNumber: form?.errors?.gas_safe_licence_card_number,
                                gasSafeConfirmed: form?.errors?.user_gas_safe_confirmed,
                            }}
                            type={'engineer'}
                        />
                    </>)}
                </CardBody>
            </Card>
        )
    }

    return (
        <PageLayout title={title}>
            <WidthConstrainedContainer>
                {componentLoading ? (
                    <div className="d-flex justify-content-center align-items-center vh-100">
                        <Spinner/>
                    </div>
                ) : (
                    <>
                        <div ref={formRef}>
                            {/* Details */}
                            <Card>
                                <CardBody>
                                    <SubTitle
                                        className="mb-3"
                                        style={{color: '#464C5E'}}
                                        title={StringHelpers.title(
                                            (crud?.models?.company || 'Company') + ' ' +
                                            (crud?.sub_titles?.details || 'Details'))
                                        }
                                    />
                                    {form.Input({
                                        name: 'name',
                                        label: StringHelpers.title(fields?.name || 'Name'),
                                        type: 'text',
                                    })}
                                    {themes.isToshiba && (
                                        <FormInput
                                            type="select"
                                            name="type"
                                            label={StringHelpers.title(fields?.type || 'Type')}
                                            placeholder={StringHelpers.title(
                                                replaceKeyWithValue(crud?.placeholders?.select, 'model', '')
                                            )}
                                            value={form?.store?.type}
                                            onChange={(v) => {
                                                form?.setValues({type: v as any});
                                                fetchRoles(v as any);
                                            }}
                                            options={selects?.company_types}
                                        />
                                    )}
                                    {form?.store?.type === CompanyTypes.DISTRIBUTOR && themes.isToshiba && (
                                        <FormInput
                                            type="searchable-select"
                                            name="distributors"
                                            label={StringHelpers.title(
                                                fields?.distributor_ids || 'Distributors'
                                            )}
                                            placeholder={StringHelpers.title(
                                                replaceKeyWithValue(crud?.placeholders?.select, 'model', '')
                                            )}
                                            value={form?.store?.distributor_ids}
                                            onChange={(v) =>
                                                form?.setValues({
                                                    distributor_ids: v as any,
                                                })
                                            }
                                            isMulti
                                            options={selects?.companies}
                                            disabled={
                                                disabled || user?.role_name === Roles.DISTRIBUTION_MANAGER
                                            }
                                        />
                                    )}
                                    <PostcodeLookup
                                        data={form?.store}
                                        setData={form?.setValues}
                                        errors={form?.errors}
                                    />
                                    {/* Additional Platform fields */}
                                    {themes.isViessmann && (<>
                                        <SubTitle
                                            className="mb-3 mt-5"
                                            style={{color: '#464C5E'}}
                                            title={StringHelpers.title(
                                                `${crud?.sub_titles?.additional_platform} ${crud?.sub_titles?.details}`
                                            )}
                                        />
                                        {/* system types */}
                                        {renderSystemTypes()}
                                        {themes.isViessmann && form?.Input({
                                            name: 'sap_number',
                                            label: StringHelpers.title(fields?.sap_number || 'SAP number'),
                                            type: 'text',
                                        })}
                                        {themes.isViessmann && (form?.store.solar_systems_enabled || form?.store.heat_pump_systems_enabled) &&
                                            form?.Input({
                                                name: 'mcs_number',
                                                label: StringHelpers.title(fields?.mcs_number || 'MCS number'),
                                                type: 'text',
                                            })
                                        }
                                        <div className="row">
                                            <ColumnLayout>
                                                <FormInput
                                                    type="select"
                                                    name="customer_email_enabled"
                                                    label={StringHelpers.title(
                                                        fields?.customer_email_enabled || 'Customer email enabled'
                                                    )}
                                                    placeholder={StringHelpers.title(
                                                        replaceKeyWithValue(crud?.placeholders?.select, 'model', '')
                                                    )}
                                                    value={form?.store?.customer_email_enabled}
                                                    onChange={(v) =>
                                                        form?.setValues({
                                                            customer_email_enabled:
                                                                typeof v === 'string' ? JSON.parse(v) : v,
                                                        })
                                                    }
                                                    options={booleanOptions}
                                                />
                                            </ColumnLayout>
                                            <ColumnLayout>
                                                <FormInput
                                                    type="select"
                                                    name="system_access_enabled"
                                                    label={StringHelpers.title(fields?.system_access_enabled)}
                                                    placeholder={StringHelpers.title(
                                                        replaceKeyWithValue(crud?.placeholders?.select, 'model', '')
                                                    )}
                                                    value={form?.store?.system_access_enabled}
                                                    onChange={(v) =>
                                                        form?.setValues({
                                                            system_access_enabled:
                                                                typeof v === 'string' ? JSON.parse(v) : v,
                                                        })
                                                    }
                                                    options={booleanOptions}
                                                />
                                            </ColumnLayout>
                                        </div>
                                    </>)}
                                    {form?.store?.type === CompanyTypes.INSTALLER && themes.isToshiba && renderToshibaInstallerFields()}
                                    {form?.store?.type === CompanyTypes.DISTRIBUTOR && themes.isToshiba && renderToshibaDistributorFields()}
                                    {themes.isViessmann && form.store.gas_boiler_systems_enabled && renderGasSafeFields()}
                                </CardBody>
                            </Card>
                            {/* Primary User */}
                            {!isEdit && renderPrimaryUserFields()}
                        </div>

                        <BottomAnchoredControls>
                            <Button
                                label={StringHelpers.title(crud?.buttons.back.default || 'Back')}
                                onClick={() =>
                                    isEdit ? navigate(`/companies/${companyId}`) : navigate('/companies')
                                }
                                variant={Variants.Dark}
                                type={ButtonTypes.Outline}
                                className={'me-4'}
                                disabled={disabled}
                            />
                            <Submit
                                defaultLabel={StringHelpers.title(
                                    isEdit
                                        ? crud?.buttons.edit.default || 'Update'
                                        : crud?.buttons.create.default || 'Create'
                                )}
                                loadingLabel={StringHelpers.title(
                                    isEdit
                                        ? crud?.buttons.edit.submitting || 'Updating...'
                                        : crud?.buttons.create.submitting || 'Creating...'
                                )}
                            />
                        </BottomAnchoredControls>
                    </>
                )}
            </WidthConstrainedContainer>
        </PageLayout>
    );
}

export default CompanyForm;
