import {
   API_ENDPOINTS,
   Button,
   Card,
   CardBody,
   customToast,
   DataTable,
   DateFormats,
   Dropdown,
   formatDate,
   FormInput,
   Icon,
   Icons,
   ImageType,
   Modal,
   Permissions,
   replaceKeyWithValue,
   route,
   StringHelpers,
   SubTitle,
   useAuthorisationContext,
   useDisabledContext,
   useLangContext,
   useQuery,
   Variants,
   SearchableSelectInputAsync,
} from 'carrier-fe';
import debounce from 'debounce-promise';
import { useState, useEffect, useMemo } from 'react';
import { EventInviteType, EventType, InviteeType } from '../../../../types/event';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { isFuture, parse, set } from 'date-fns';

type Props = {
   store: EventType;
   fetchEventData: () => void;
};

const DEBOUNCE_DELAY = 500;

function EventInvites({ store, fetchEventData }: Props) {
   const { crud, fields } = useLangContext();
   const { eventId } = useParams();
   const { disabled, setDisabled } = useDisabledContext();
   const { hasPolicyAccess } = useAuthorisationContext();

   const [refreshOnChange, setRefreshOnChange] = useState(new Date().toISOString());
   const [itemToArchive, setItemToArchive] = useState<EventInviteType | null>(null);
   const [refundIfPaid, setRefundIfPaid] = useState<boolean>(false);
   const [isInviteModalOpen, setIsInviteModalOpen] = useState(false);
   const [selectedInvitees, setSelectedInvitees] = useState<string[]>([]);
   const [archiveModalOpen, setArchiveModalOpen] = useState(false);
   const [archiving, setArchiving] = useState(false);
   const [search, setSearch] = useState('');
   const [invitees, setInvitees] = useState<InviteeType[]>([]);
   const {
      data: inviteesRes,
      isLoading: isLoadingInvitees,
      refetch,
   } = useQuery<{ data: any }>(
      route(`${API_ENDPOINTS.ADMIN.TRAINING.EVENT.INVITE.INVITEES}?filter[search]=${search}`, {
         trainingEvent: String(eventId),
      }),
      {
         method: 'GET',
      }
   );

   useEffect(() => {
      if (archiving) archive();
   }, [archiving]);

   useEffect(() => {
      fetchEventData();
   }, [refreshOnChange]);

   useEffect(() => {
      if (inviteesRes?.data) {
         updateInvitees(inviteesRes.data);
      }
   }, [inviteesRes]);

   const updateInvitees = (data: InviteeType[]) => {
      // filter out duplicates using id with current state of invitees
      const newInvitees = [...invitees, ...data];
      const uniqueArray = <T extends { id: number | string }>(arr: T[]): T[] =>
         Object.values(arr.reduce<Record<string, T>>((acc, obj) => ((acc[obj.id] = obj), acc), {}));

      setInvitees(uniqueArray(newInvitees));
   };

   const handleOptionSelect = (action: string | ImageType | null, data: EventInviteType) => {
      if (action === 'remove') {
         setItemToArchive(data);
         setArchiveModalOpen(true);
      }
   };

   const archive = async () => {
      setDisabled(true);
      try {
         const response = await axios.delete(
            route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.INVITE.DELETE, {
               trainingEvent: String(eventId),
               invite: itemToArchive?.id || '',
            }),
            { data: { refund_if_paid: refundIfPaid } }
         );
         customToast({
            title: response?.data?.message || 'Success.',
            variant: Variants.Success,
         });
         setRefreshOnChange(new Date().toISOString());
         refetch();
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message || 'An error occurred. Please try again.',
            variant: Variants.Danger,
         });
      } finally {
         setDisabled(false);
         setArchiving(false);
         setArchiveModalOpen(false);
      }
   };

   const confirmInvite = async () => {
      setDisabled(true);
      try {
         const { data } = await axios.post(
            route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.INVITE.STORE, {
               trainingEvent: String(eventId),
            }),
            { attendees: selectedInvitees }
         );
         customToast({
            title: data.message || 'Success. Invite sent.',
            variant: Variants.Success,
         });
         setRefreshOnChange(new Date().toISOString());
         setIsInviteModalOpen(false);
         setSelectedInvitees([]);
         refetch();
      } catch (error: any) {
         customToast({
            title: error?.response?.data?.message || 'An error occurred. Please try again.',
            variant: Variants.Danger,
         });
      } finally {
         setDisabled(false);
      }
   };

   const getActions = () => {
      if (
         !store ||
         !hasPolicyAccess({
            name: 'individual_training_event',
            method: 'create',
         }) ||
         !!store.completed_at ||
         !!store.deleted_at ||
         !store.can_be_booked
      ) {
         return [];
      }

      return [
         {
            label: StringHelpers.title(fields?.invite || 'Invite'),
            disabled: false,
            onClick: () => setIsInviteModalOpen(true),
            icon: <Icon icon={Icons.ADD} />,
         },
      ];
   };

   let items = getActions();

   const loadInviteeOptions = debounce(async (search: string) => {
      if (!search) {
         return Promise.resolve({ options: [] });
      }
      const response = await axios.get(
         route(`${API_ENDPOINTS.ADMIN.TRAINING.EVENT.INVITE.INVITEES}?filter[search]=${search}`, {
            trainingEvent: String(eventId),
         })
      );
      updateInvitees(response.data.data);
      return response.data.data
         ?.filter((invitee: any) => !selectedInvitees.includes(invitee.id))
         .map((item: any) => ({
            label: `${item.first_name} ${item.last_name} (${item.email})`,
            value: item.id,
         }));
   }, DEBOUNCE_DELAY);

   const colMapping = (data: EventInviteType) => {
      return [
         {
            id: 'name',
            label: StringHelpers.title(fields?.name || 'Name'),
            value: StringHelpers.title(data.name || '-'),
            sortable: true,
         },
         {
            id: 'email',
            label: StringHelpers.title(fields?.email || 'Email'),
            value: data.email || '-',
            sortable: true,
         },
         {
            id: 'paid',
            label: StringHelpers.title(fields?.paid || 'Paid'),
            value: data.paid === '1' ? 'Y' : 'N',
            sortable: true,
         },
         {
            id: 'accepted',
            label: StringHelpers.title(fields?.accepted || 'Accepted'),
            value: data.accepted ? 'Y' : 'N',
            sortable: true,
         },
         {
            id: 'actions',
            label: StringHelpers.title(fields?.actions || 'Actions'),
            value: data.permissions?.delete && (
               <FormInput
                  type="select"
                  label={StringHelpers.title(fields?.actions || 'Action')}
                  placeholder={StringHelpers.title(fields?.select || 'Select')}
                  name="actions"
                  value=""
                  onChange={(value) => {
                     if (typeof value === 'string') handleOptionSelect(value, data);
                  }}
                  options={[
                     {
                        label: StringHelpers.title(crud?.remove || 'Remove'),
                        value: 'remove',
                     },
                  ]}
                  noMargin
                  disabled={disabled}
               />
            ),
         },
      ];
   };

   useEffect(() => {
      if (store.event_at) {
         let eventAt = parse(store.event_at, DateFormats.DB, new Date());

         if (isFuture(eventAt)) {
            setRefundIfPaid(true);
         } else {
            setRefundIfPaid(false);
         }
      }
   }, [store.event_at]);

   return (
      <>
         <SubTitle
            title={StringHelpers.title(fields?.attendees || 'Attendees')}
            style={{ color: '#464C5E' }}
            className="mb-4"
         />

         <DataTable<EventInviteType>
            routeEndpoint={route(API_ENDPOINTS.ADMIN.TRAINING.EVENT.INVITE.INDEX, {
               trainingEvent: String(eventId),
            })}
            colMapping={colMapping}
            items={items}
            incrementPaginationButtons
            refreshOnChange={refreshOnChange}
            variant={Variants.Info}
         />

         <Modal
            open={archiveModalOpen}
            onClose={() => setArchiveModalOpen(false)}
            title={StringHelpers.title(
               replaceKeyWithValue(
                  crud?.modals?.remove?.title || 'Remove :name?',
                  'name',
                  crud?.models?.training_invite || 'Training Invite'
               )
            )}
            confirmText={
               archiving
                  ? crud?.buttons?.remove?.submitting || 'Removing...'
                  : crud?.buttons?.remove?.default || 'Remove'
            }
            closeText={crud?.buttons?.cancel?.default || 'Cancel'}
            onConfirm={() => setArchiving(true)}
            disabled={disabled || archiving}
            variant={Variants.Danger}
         >
            <p>
               {replaceKeyWithValue(
                  fields?.invite_modal_delete_description ||
                     'Are you sure you want to remove the invite for :name?',
                  'name',
                  itemToArchive?.name || ''
               )}
            </p>
            {store?.price_pence !== null && (
               <FormInput
                  type="select"
                  label={StringHelpers.title(fields?.refund_if_paid || 'Refund If Paid')}
                  name="refund_if_paid"
                  value={refundIfPaid ? '1' : '0'}
                  onChange={(value) => setRefundIfPaid(value === '1')}
                  options={crud?.options?.boolean || []}
                  disabled={disabled || archiving}
               />
            )}
         </Modal>

         <Modal
            open={isInviteModalOpen}
            onClose={() => {
               setIsInviteModalOpen(false);
               setSelectedInvitees([]);
            }}
            title={StringHelpers.title(fields?.invite_attendees || 'Invite Attendees')}
            confirmText={
               disabled
                  ? crud?.buttons?.invite?.submitting || 'Inviting...'
                  : crud?.buttons?.invite?.default || 'Invite'
            }
            onConfirm={confirmInvite}
            disabled={disabled}
            variant={Variants.Primary}
         >
            <div style={{ minHeight: '15rem' }}>
               <SearchableSelectInputAsync
                  label={StringHelpers.title(fields?.attendees || 'Attendees')}
                  name="users"
                  value={selectedInvitees}
                  defaultOptions={inviteesRes?.data
                     ?.filter((invitee: any) => !selectedInvitees.includes(invitee.id))
                     .map((item: any) => ({
                        label: `${item.first_name} ${item.last_name} (${item.email})`,
                        value: item.id,
                     }))}
                  loadOptions={loadInviteeOptions}
                  cacheOptions
                  options={inviteesRes?.data}
                  onInputChange={setSearch}
                  onChange={(value) => {
                     if (typeof value === 'string' && value !== '') {
                        setSelectedInvitees([...selectedInvitees, value]);
                     }
                  }}
                  placeholder={StringHelpers.title(
                     replaceKeyWithValue(
                        crud?.placeholders?.search || 'Search :model',
                        'model',
                        fields?.attendees || 'Attendees'
                     )
                  )}
                  loading={isLoadingInvitees}
               />
               {selectedInvitees.length > 0 && (
                  <>
                     <p style={{ fontSize: '14px' }}>
                        {fields?.invite_modal_description ||
                           'You are about to invite the following user(s) to this event. They will receive an email containing the details but will still need to manually join the event.'}
                     </p>
                     {invitees
                        ?.filter((invitee: any) => selectedInvitees.includes(invitee.id))
                        .map((invitee: any) => (
                           <Card key={invitee.id}>
                              <CardBody className="position-relative">
                                 <strong>
                                    {StringHelpers.title(
                                       invitee.first_name + ' ' + invitee.last_name
                                    )}
                                 </strong>
                                 <div>{invitee.email}</div>
                                 <button
                                    onClick={() =>
                                       setSelectedInvitees(
                                          selectedInvitees.filter((id) => id !== invitee.id)
                                       )
                                    }
                                    className="btn p-1 d-flex position-absolute"
                                    style={{
                                       top: '1rem',
                                       right: '1rem',
                                    }}
                                 >
                                    <Icon icon={Icons.DELETE} />
                                 </button>
                              </CardBody>
                           </Card>
                        ))}
                  </>
               )}
            </div>
         </Modal>
      </>
   );
}

export default EventInvites;
