import axios from 'axios';
import {
  API_ENDPOINTS,
  Button,
  ButtonTypes,
  Card,
  CardBody,
  customToast,
  Dropdown,
  formatDate,
  Icons,
  Modal,
  PageLayout,
  replaceKeyWithValue,
  route,
  Sizes,
  StringHelpers,
  SubTitle,
  useDisabledContext,
  useLangContext,
  useStore,
  Variants,
  WidthConstrainedContainer,
} from 'carrier-fe';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { CourseType } from '../../../types/course';
import { DropdownItemProps } from 'carrier-fe/lib/components/Controls/Dropdown/DropdownItem/DropdownItem';
import Stages from './components/Stages';

function Course() {
  const { courseId } = useParams();

  const { crud, fields } = useLangContext();
  const { disabled, setDisabled } = useDisabledContext();
  const navigate = useNavigate();

  const { store, setValues } = useStore<CourseType>({} as CourseType);

  const [archiveModalOpen, setArchiveModalOpen] = useState<boolean>(false);
  const [archiving, setArchiving] = useState<boolean>(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const TEXT_START = 0;
  const MAX_TEXT_LENGTH = 100;

  const toggleExpand = () => setIsExpanded(!isExpanded);

  useEffect(() => {
    fetchCourseData();
  }, [courseId]);

  useEffect(() => {
    if (archiving) {
      archive();
    }
  }, [archiving]);

  const fetchCourseData = useCallback(async () => {
    try {
      const { data } = await axios.get<{ data: CourseType }>(
        route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.VIEW, {
          trainingCourse: String(courseId),
        })
      );
      setValues(data.data);
    } catch (err: any) {
      customToast({
        title:
          err?.response?.data?.message ||
          'An error occurred. Please try again.',
        variant: Variants.Danger,
      });
    }
  }, [courseId]);

  const archive = async () => {
    setDisabled(true);

    try {
      let response = await axios.delete(
        route(API_ENDPOINTS.ADMIN.TRAINING.COURSE.DELETE, {
          trainingCourse: String(courseId),
        })
      );
      customToast({
        title: response?.data?.message || 'Success.',
        variant: Variants.Success,
      });
      await fetchCourseData();
    } catch (error: any) {
      customToast({
        // @ts-ignore
        title:
          error?.response?.data?.message ||
          'An error occurred. Please try again.',
        variant: Variants.Danger,
      });
    } finally {
      setDisabled(false);
      setArchiving(false);
      setArchiveModalOpen(false);
    }
  };

  const getActions = (): DropdownItemProps[] => {
    if (!store || !store.permissions) return [];

    let actions: DropdownItemProps[] = [];

    if (store?.permissions.update) {
      actions.push({
        label: StringHelpers.title(
          crud?.buttons.edit.default || 'Update'
        ),
        onClick: () => navigate(`/training/course/${courseId}/edit`),
        variant: Variants.Dark,
        icon: Icons.EDIT,
        disabled: disabled,
      });
    }

    if (store?.permissions.delete) {
      actions.push({
        label: StringHelpers.title(
          crud?.buttons.delete.default || 'Delete'
        ),
        onClick: () => setArchiveModalOpen(true),
        variant: Variants.Danger,
        icon: Icons.DELETE,
        disabled: disabled,
      });
    }

    return actions;
  };

  let title = replaceKeyWithValue(
    crud?.pages?.view.title || 'View :model',
    'model',
    crud?.models?.training_course || 'Training Course'
  );

  let actions = getActions();

  return (
    <PageLayout title={StringHelpers.title(title)}>
      <WidthConstrainedContainer>
        <div className="mb-4 d-flex justify-content-between align-items-center">
          <Button
            label={StringHelpers.title(
              crud?.buttons?.back?.default || 'Back'
            )}
            onClick={() => navigate('/training/course')}
            variant={Variants.Dark}
            type={ButtonTypes.Outline}
            disabled={disabled}
          />
          {actions.length > 0 && (
            <Dropdown
              label={StringHelpers.title(
                crud?.buttons?.actions?.default || 'Actions'
              )}
              items={actions}
              type={ButtonTypes.Solid}
              variant={Variants.Dark}
              disabled={disabled}
            />
          )}
        </div>
        <Card className="flex-column-reverse flex-xl-row overflow-hidden rounded-4">
          <CardBody>
            <SubTitle
              title={StringHelpers.title(
                crud?.sub_titles?.details || 'Details'
              )}
              className="mb-3 w-100"
              style={{ color: '#464C5E' }}
            />
            <div className="row row-cols-lg-2">
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.name || 'Name'
                  )}
                </strong>
                <div>{store?.name || '-'}</div>
              </div>
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.category || 'Category'
                  )}
                </strong>
                <div>
                  {store?.training_category?.name || '-'}
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.description || 'Description'
                  )}
                </strong>
                <div>
                  {isExpanded &&
                    store?.description?.length > MAX_TEXT_LENGTH
                    ? store?.description || '-'
                    : store?.description
                      ? StringHelpers.limit(
                        store?.description,
                        MAX_TEXT_LENGTH
                      )
                      : '-'}
                </div>
                {store?.description?.length >
                  MAX_TEXT_LENGTH && (
                    <div className="d-flex flex-column mb-3 w-25">
                      <Button
                        label={
                          isExpanded
                            ? crud?.buttons.see_less
                              .default ||
                            'See Less'
                            : crud?.buttons.see_more
                              .default ||
                            'See More'
                        }
                        onClick={toggleExpand}
                        variant={Variants.Dark}
                        type={ButtonTypes.Outline}
                        size={Sizes.Small}
                        style={{
                          width: 90,
                          marginTop: '1rem',
                        }}
                      />
                    </div>
                  )}
              </div>
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.requires_in_person_event ||
                    'Required In Person Event'
                  )}
                </strong>
                <div>
                  {StringHelpers.title(
                    store?.requires_in_person_event === '1'
                      ? fields?.yes || 'Yes'
                      : fields?.no || 'No'
                  )}
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.course_expiry_years ||
                    'Course Expiry (Years)'
                  )}
                </strong>
                <div>{store?.course_expiry_years || '-'}</div>
              </div>
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.created_at || 'created'
                  )}
                </strong>
                <div>
                  {store?.created_at
                    ? formatDate(store.created_at)
                    : '-'}
                </div>
              </div>
              <div className="d-flex flex-column mb-3">
                <strong>
                  {StringHelpers.title(
                    fields?.updated_at || 'Updated'
                  )}
                </strong>
                <div>
                  {store?.updated_at
                    ? formatDate(store.updated_at)
                    : '-'}
                </div>
              </div>
              {!!store?.deleted_at && (
                <div className="d-flex flex-column mb-3">
                  <strong>
                    {StringHelpers.title(
                      fields?.archived_at || 'archived'
                    )}
                  </strong>
                  <div>
                    {store?.deleted_at
                      ? formatDate(store.deleted_at)
                      : '-'}
                  </div>
                </div>
              )}
            </div>
          </CardBody>
          {store?.image?.url && (
            <img
              src={store.image.url}
              className="object-fit-cover mx-auto w-100"
              style={{
                maxWidth: '500px',
                maxHeight: '500px',
                aspectRatio: '1/1'
              }}
            />
          )}
        </Card>
        <Stages
          store={store}
          fetchCourseData={fetchCourseData}
        />
      </WidthConstrainedContainer>

      <Modal
        open={archiveModalOpen}
        onClose={() => setArchiveModalOpen(false)}
        title={StringHelpers.title(
          replaceKeyWithValue(
            crud?.modals?.delete.title || 'Delete :name?',
            'name',
            crud?.models?.training_course || 'Training Course'
          )
        )}
        confirmText={
          archiving
            ? crud?.buttons.delete.submitting || 'Deleting...'
            : crud?.buttons.delete.default || 'Delete'
        }
        closeText={crud?.buttons.cancel.default || 'Cancel'}
        onConfirm={() => setArchiving(true)}
        disabled={disabled || archiving}
        variant={Variants.Danger}
      >
        {!!store && (
          <>
            {crud?.modals?.delete.description.map(
              (item: string, key: number) => {
                return (
                  <p key={key}>
                    {replaceKeyWithValue(
                      item,
                      'name',
                      store?.name
                    )}
                  </p>
                );
              }
            )}
            <p>
              {StringHelpers.title(fields?.training_category_id)}:{' '}
              {StringHelpers.title(
                store?.training_category?.name || ''
              )}
            </p>
            {/* TODO - Implement users */}
            <p>
              {StringHelpers.title(
                fields?.assigned_users + ' ' + 0
              )}
            </p>
          </>
        )}
      </Modal>
    </PageLayout>
  );
}

export default Course;
