import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Root from '../pages/root';
import Dashboard from '../pages/dashboard';
import {
	AuthenticatedLayout,
	ErrorPage,
	Permissions,
	Platforms,
	ProtectedPage,
} from 'carrier-fe';

import CourseList from '../pages/course';
import CourseForm from '../pages/course/form';
import Course from '../pages/course/view';

import VenueList from '../pages/venue';
import VenueForm from '../pages/venue/form';
import Venue from '../pages/venue/view';

import EventList from '../pages/event';
import EventForm from '../pages/event/form';
import Event from '../pages/event/view';

import QuizList from '../pages/quiz';
import QuizForm from '../pages/quiz/form';
import Quiz from '../pages/quiz/view';
import EventComplete from '../pages/event/complete';
import StageForm from '../pages/course/stage/form';
import UserList from '../pages/users';
import User from '../pages/users/view';
import UserForm from '../pages/users/form';
import CompaniesList from '../pages/companies';
import Company from '../pages/companies/view';
import CompanyForm from '../pages/companies/form';
import EmailTemplateList from '../pages/email';
import EmailTemplate from '../pages/email/edit';
import ReportingList from '../pages/reporting';

function Router() {
	return (
		<BrowserRouter>
			<Routes>
				<Route
					index
					path="/"
					element={<Root />}
				/>

				{/*
				 * TODO - FOLLOWING ROUTES NEED TO BE AUTH PROTECTED
				 *      - IS A TOKEN SET IN THE COOKIE?
				 *      - IS THAT TOKEN VALID (CHECK VIA THE SERVER?
				 *      - IF NOT -> LOGOUT AND REDIRECT TO LOGIN
				 */}
				<Route
					path="/"
					element={<AuthenticatedLayout platform={Platforms.ADMIN} />}
				>
					<Route
						index
						path="/dashboard"
						element={
							<ProtectedPage
								requiredPermissions={
									Permissions.ADMIN_PLATFORM_VIEW
								}
							>
								<Dashboard />
							</ProtectedPage>
						}
					/>

					<Route path="/training/course">
						<Route
							index
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_TRAINING_COURSE_INDEX
									}
								>
									<CourseList />
								</ProtectedPage>
							}
						/>
						<Route
							path=":courseId"
							element={<Course />}
						/>
						<Route
							path="create"
							element={<CourseForm />}
						/>
						<Route
							path=":courseId/edit"
							element={<CourseForm />}
						/>
						<Route
							path=":courseId/stage"
							element={<StageForm />}
						/>
						<Route
							path=":courseId/stage/:courseStageId/edit"
							element={<StageForm />}
						/>
					</Route>

					<Route path="/training/venue">
						<Route
							index
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_TRAINING_VENUE_INDEX
									}
								>
									<VenueList />
								</ProtectedPage>
							}
						/>
						<Route
							path=":venueId"
							element={<Venue />}
						/>
						<Route
							path="create"
							element={<VenueForm />}
						/>
						<Route
							path=":venueId/edit"
							element={<VenueForm />}
						/>
					</Route>

					<Route path="/training/event">
						<Route
							index
							element={<EventList />}
						/>
						<Route
							path=":eventId"
							element={<Event />}
						/>
						<Route
							path="create"
							element={<EventForm />}
						/>
						<Route
							path=":eventId/edit"
							element={<EventForm />}
						/>
						<Route
							path=":eventId/complete"
							element={<EventComplete />}
						/>
					</Route>

					<Route path="/training/quiz">
						<Route
							index
							element={<QuizList />}
						/>
						<Route
							path=":quizId"
							element={<Quiz />}
						/>
						<Route
							path="create"
							element={<QuizForm />}
						/>
						<Route
							path=":quizId/edit"
							element={<QuizForm />}
						/>
					</Route>

					<Route path="/user">
						<Route
							index
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_USER_INDEX
									}
								>
									<UserList />
								</ProtectedPage>
							}
						/>
						<Route
							path=":userId"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_USER_SHOW
									}
								>
									<User />
								</ProtectedPage>
							}
						/>
						<Route
							path="create"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_USER_STORE
									}
								>
									<UserForm />
								</ProtectedPage>
							}
						/>
						<Route
							path=":userId/edit"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_USER_UPDATE
									}
								>
									<UserForm />
								</ProtectedPage>
							}
						/>
					</Route>

					<Route path="/companies">
						<Route
							index
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_COMPANY_INDEX
									}
								>
									<CompaniesList />
								</ProtectedPage>
							}
						/>
						<Route
							path=":companyId"
							element={<Company />}
						/>
						<Route
							path="create"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_COMPANY_STORE
									}
								>
									<CompanyForm />
								</ProtectedPage>
							}
						/>
						<Route
							path=":companyId"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_COMPANY_SHOW
									}
								>
									<Company />
								</ProtectedPage>
							}
						/>
						<Route
							path=":companyId/edit"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_COMPANY_UPDATE
									}
								>
									<CompanyForm />
								</ProtectedPage>
							}
						/>
					</Route>

					<Route path="/email-templates">
						<Route
							index
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.NOTIFICATION_TEMPLATE_INDEX
									}
								>
									<EmailTemplateList />
								</ProtectedPage>
							}
						/>
						<Route
							path=":templateId"
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.NOTIFICATION_TEMPLATE_UPDATE
									}
								>
									<EmailTemplate />
								</ProtectedPage>
							}
						/>
					</Route>

					<Route path="/reporting">
						<Route
							index
							element={
								<ProtectedPage
									requiredPermissions={
										Permissions.ADMIN_REPORT_INDEX
									}
								>
									<ReportingList />
								</ProtectedPage>
							}
						/>
					</Route>
				</Route>

				<Route
					path="*"
					element={<ErrorPage />}
				/>
			</Routes>
		</BrowserRouter>
	);
}

export default Router;
