import {
	API_ENDPOINTS,
	DataTable,
	Icon,
	Icons,
	PageLayout,
	route,
	StringHelpers,
	useAuthorisationContext,
	useLangContext,
	Variants,
	Permissions,
	customToast,
	downloadFile,
	DateFormats,
} from 'carrier-fe';
import { useMemo } from 'react';
import { AddressType } from '../../types';
import { format, formatDate, subMonths } from 'date-fns';

interface InstallationData {
	id: number;
	name: string;
	completion_date: string;
	customer_name: string;
	appliances: {
		serial_number: string;
		appliance_details: {
			full_name: string;
		};
	}[];
	address_line_1?: string,
	address_line_2?: string,
	address_line_3?: string,
	town_city?: string,
	state_county?: string,
	postcode_zipcode?: string,
	local_authority_code?: string,
	sub_country_name?: string,
	country_code_iso_3?: string,
	country?: string,
}

function ReportingList() {
	const { crud, fields, responses } = useLangContext();
	const { hasPermissionAccess } = useAuthorisationContext();

	const searchParams = new URLSearchParams(window.location.search);
	const startDate = searchParams.get('filter[start_date]');
	const endDate = searchParams.get('filter[end_date]');

	const exportReport = async () => {
		const fileName = `viessmann-registration-report-${format(
			new Date(),
			'yyyy-MM-dd-HH:mm'
		).replace('_', ':')}.csv`;

		try {
			await downloadFile(
				route(API_ENDPOINTS.ADMIN.INSTALLATION_WARRANTY.EXPORT),
				fileName,
				{
					start_date: startDate,
					end_date: endDate,
				}
			);
			customToast({
				title:
					responses?.admin.reporting.export.success ||
					'Success. Registration report downloaded successfully.',
				variant: Variants.Success,
			});
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message || '',
				variant: Variants.Danger,
			});
		}
	};

	const items = useMemo(() => {
		if (!hasPermissionAccess(Permissions.ADMIN_REPORT_EXPORT)) return [];

		return [
			{
				label: StringHelpers.title(
					crud?.buttons?.export?.default || 'Export'
				),
				disabled: false,
				onClick: () => exportReport(),
				icon: <Icon icon={Icons.ADD} />,
			},
		];
	}, []);

	const colMapping = (data: InstallationData) => [
		// TODO: We don't have this in the response
		{
			id: 'referer',
			label: StringHelpers.title(fields?.referer || 'Referer'),
			value: '-',
			sortable: true,
		},
		{
			id: 'company_name',
			label: StringHelpers.title(
				fields?.installer_company_name || 'Installer Company Name'
			),
			value: data?.name || '-',
			sortable: true,
		},
		{
			id: 'completion_date',
			label: StringHelpers.title(
				fields?.completion_date || 'Work Completion Date'
			),
			value: data?.completion_date || '-',
			sortable: true,
		},
		{
			id: 'address',
			label: StringHelpers.title(fields?.address || 'Address'),
			value: data?.address_line_1
				? [
						data?.address_line_1,
						data?.address_line_2,
						data?.town_city,
						data?.state_county,
				  ]
						.filter(Boolean) // Removes null, undefined, and empty strings
						.join(', ')
				: '-',
			sortable: false,
		},
		{
			id: 'postcode_zipcode',
			label: StringHelpers.title(
				fields?.postcode_zipcode || 'Postcode / Zipcode'
			),
			value: data?.postcode_zipcode || '-',
			sortable: false,
		},
		{
			id: 'contact_name',
			label: StringHelpers.title(fields?.contact_name || 'Contact Name'),
			value: data?.customer_name || '-',
			sortable: true,
		},
		{
			id: 'serial_number',
			label: StringHelpers.title(
				fields?.serial_number || 'Serial Number'
			),
			value: data?.appliances[0]?.serial_number || '-',
			sortable: false,
		},
		{
			id: 'product',
			label: StringHelpers.title(fields?.product || 'Product'),
			value: data?.appliances[0]?.['appliance_details']?.full_name || '-',
			sortable: false,
		},
	];

	const title = useMemo(
		() =>
			StringHelpers.title(
				crud?.models.registration_report || 'Registration Reports'
			),
		[crud]
	);

	return (
		<PageLayout title={title}>
			<DataTable<InstallationData>
				routeEndpoint={route(
					API_ENDPOINTS.ADMIN.INSTALLATION_WARRANTY.INDEX
				)}
				colMapping={colMapping}
				items={items}
				filters={[
					{
						name: 'start_date',
						label: StringHelpers.title(
							fields?.start_date || 'Start Date'
						),
						type: 'date',
						value: formatDate(
							subMonths(new Date(), 3).setHours(0, 0, 0, 0),
							DateFormats.DB
						),
					},
					{
						name: 'end_date',
						label: StringHelpers.title(
							fields?.end_date || 'End Date'
						),
						type: 'date',
						value: formatDate(
							new Date().setHours(0, 0, 0, 0),
							DateFormats.DB
						),
					},
				]}
				incrementPaginationButtons
				variant={Variants.Info}
			/>
		</PageLayout>
	);
}

export default ReportingList;
