import axios from 'axios';
import {
	API_ENDPOINTS,
	BottomAnchoredControls,
	Button,
	ButtonTypes,
	Card,
	CardBody,
	customToast,
	PageLayout,
	replaceKeyWithValue,
	route,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useForm,
	useLangContext,
	Variants,
	WidthConstrainedContainer,
	PostcodeLookup,
	useAuthorisationContext,
	Spinner,
	GasSafeLookup,
	checkTheme,
	Themes,
	PhoneNumberField,
	useUserContext,
	OptionsType,
} from 'carrier-fe';
import { useEffect, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { UserViewType } from '../../../types/user';

const defaultData = {
	first_name: '',
	last_name: '',
	email: '',
	username: '',
	mobile_phone_prefix: '',
	mobile_phone_number: '',
	landline_phone_prefix: '',
	landline_phone_number: '',
	address_id: '',
	local_authority_code: '',
	address_line_1: '',
	address_line_2: '',
	address_line_3: '',
	town_city: '',
	state_county: '',
	postcode_zipcode: '',
	country: '',
	sub_country_name: '',
	company_id: '',
	role_id: '',
	system_access_enabled: '0',
	gas_safe_licence_card_number: '',
	gas_safe_confirmed: false,
	username_update: '1',
};

type Data = typeof defaultData;

function UserForm() {
	const { userId } = useParams();
	const isEdit = userId ? true : false;

	const { user } = useUserContext();
	const { crud, fields } = useLangContext();
	const { disabled } = useDisabledContext();
	const navigate = useNavigate();

	const [backupEmail, setBackupEmail] = useState('');
	const [isFetching, setIsFetching] = useState(false);
	const [loading, setLoading] = useState(false);
	const [roles, setRoles] = useState<OptionsType>([]);
	const [companies, setCompanies] = useState<OptionsType>([]);
	const { hasRoleAccess } = useAuthorisationContext();
	const [searchParams] = useSearchParams();
	const lastCompanyIdRef = useRef<string | undefined>(undefined);

	const isPrivilegedUser =
		hasRoleAccess('super_admin') ||
		hasRoleAccess('platform_manager') ||
		hasRoleAccess('distribution_manager');

	const fetchData = async () => {
		if (isFetching) return;
		setIsFetching(true);
		setLoading(true);

		try {
			const { data } = await axios.get<{ data: UserViewType }>(
				route(API_ENDPOINTS.ADMIN.USER.VIEW, { userId: String(userId) })
			);

			const flattenedData = {
				...data.data,
				...data.data.individual,
				...data.data.individual.address,
				company_id: data.data.individual.company?.id || '',
				username_update: '0',
			};

			setValues(flattenedData);
			setBackupEmail(flattenedData.email);
		} catch (error: any) {
			customToast({
				title: error?.response?.data?.message || 'An error occurred.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
			setLoading(false);
		}
	};

	const fetchRoles = async (companyId?: string) => {
		if (isFetching) return;

		lastCompanyIdRef.current = companyId;
		setIsFetching(true);

		try {
			const { data } = await axios.get<{ data: OptionsType }>(
				route(API_ENDPOINTS.GENERIC.SELECT.COMPANY_ROLES, {
					company: companyId ? companyId : '',
				})
			);
			if (lastCompanyIdRef.current === companyId) {
				setRoles(data.data);
			}
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			if (lastCompanyIdRef.current === companyId) {
				setIsFetching(false);
			}
		}
	};

	const fetchCompanies = async () => {
		if (isFetching) return;
		setIsFetching(true);

		try {
			const { data } = await axios.get<{
				data: OptionsType;
			}>(
				route(API_ENDPOINTS.GENERIC.SELECT.COMPANIES) +
					'?only_approved=1'
			);
			setCompanies(data.data);
		} catch (err: any) {
			customToast({
				title:
					err?.response?.data?.message ||
					'An error occurred. Please try again.',
				variant: Variants.Danger,
			});
		} finally {
			setIsFetching(false);
		}
	};

	const update = async (payload: typeof defaultData) => {
		return isEdit
			? axios.patch<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.USER.UPDATE, {
						userId: String(userId),
					}),
					payload
			  )
			: axios.post<{ data: Data; message: string }>(
					route(API_ENDPOINTS.ADMIN.USER.STORE),
					payload
			  );
	};

	const { Input, Submit, setValues, data, errors, store } = useForm(
		update,
		defaultData
	);

	useEffect(() => {
		if (isEdit) {
			fetchData();
		}
		fetchCompanies();
	}, []);

	useEffect(() => {
		if (data) {
			navigate(`/user/${data.id}`);
		}
	}, [data]);

	useEffect(() => {
		if (store.username_update === '1') setValues({ username: store.email });
	}, [store.email]);

	useEffect(() => {
		const companyId = searchParams.get('companyId');

		if (companyId) {
			setValues({ company_id: companyId });
			fetchRoles(companyId);
		} else {
			fetchRoles();
		}
	}, [searchParams]);

	useEffect(() => {
		if (store.company_id) {
			fetchRoles(store.company_id);
		}
	}, [store.company_id]);

	useEffect(() => {
		if (!isPrivilegedUser) {
			fetchRoles(user?.individual?.company_id);
			setValues({ company_id: user?.individual?.company_id });
		}
	}, [isPrivilegedUser]);

	const title = StringHelpers.title(
		isEdit
			? replaceKeyWithValue(
					crud?.pages?.edit.title || 'Edit :model',
					'model',
					crud?.models?.user || 'User'
			  )
			: replaceKeyWithValue(
					crud?.pages?.create.title || 'Create :model',
					'model',
					crud?.models?.user || 'User'
			  )
	);

	return (
		<PageLayout title={StringHelpers.title(title)}>
			<WidthConstrainedContainer>
				{loading ? (
					<div className="d-flex justify-content-center align-items-center vh-100">
						<Spinner />
					</div>
				) : (
					<>
						<Card>
							<CardBody>
								<SubTitle
									title={StringHelpers.title(
										crud?.sub_titles?.details || 'Details'
									)}
									className="mb-3"
									style={{ color: '#464C5E' }}
								/>
								<div className="row">
									<div className="col-12 col-md-6">
										{Input({
											name: 'first_name',
											label:
												fields?.first_name ||
												'First Name',
										})}
									</div>
									<div className="col-12 col-md-6">
										{Input({
											name: 'last_name',
											label:
												fields?.last_name ||
												'Last Name',
										})}
									</div>
								</div>
								{Input({
									name: 'email',
									label: fields?.email || 'Email',
								})}
								{Input({
									name: 'username',
									label: fields?.username || 'Username',
									disable: true,
								})}
								{isEdit && store.email !== backupEmail && (
									<div className="d-flex gap-2 w-100 align-items-center">
										<p className="w-100">
											{fields.update_username_to_match_email ||
												'Would you like to update the username to match the email?'}
										</p>
										<div className="w-25">
											{Input({
												name: 'username_update',
												label: 'Update username',
												type: 'select',
												options: crud.options.boolean,
											})}
										</div>
									</div>
								)}
								{Input({
									name: 'company_id',
									label: fields?.company || 'Company',
									type: 'searchable-select',
									options: companies,
									disable: companies.length === 0,
									visible: isPrivilegedUser,
								})}
								{Input({
									name: 'role_id',
									label: fields?.role || 'Role',
									placeholder: StringHelpers.title(
										fields?.please_select || 'Please select'
									),
									type: 'select',
									options: roles,
								})}
								<PhoneNumberField
									label={StringHelpers.title(
										fields.mobile_phone_number ||
											'Mobile number'
									)}
									name="mobile_phone_number"
									value={store?.mobile_phone_number || ''}
									onChange={(value) =>
										setValues({
											mobile_phone_number: value,
										})
									}
									disabled={disabled}
									errorMessages={errors?.mobile_phone_number}
									prefixValue={store?.mobile_phone_prefix || ''}
									prefixOnChange={(value) =>
										setValues({
											mobile_phone_prefix: value,
										})
									}
								/>
								<PhoneNumberField
									label={StringHelpers.title(
										fields?.landline_phone_number ||
											'Landline Number'
									)}
									name="landline_phone_number"
									value={store?.landline_phone_number || ''}
									onChange={(value) =>
										setValues({
											landline_phone_number: value,
										})
									}
									disabled={disabled}
									errorMessages={
										errors?.landline_phone_number
									}
									prefixValue={store?.landline_phone_prefix || ''}
									prefixOnChange={(value) =>
										setValues({
											landline_phone_prefix: value,
										})
									}
								/>
								{Input({
									name: 'system_access_enabled',
									label:
										fields?.system_access_enabled ||
										'Enable System Access',
									type: 'select',
									options: crud?.options?.boolean,
								})}
							</CardBody>
						</Card>

						<Card>
							<CardBody>
								<PostcodeLookup
									data={store}
									setData={setValues}
									errors={errors}
								/>
							</CardBody>
						</Card>

						{checkTheme(Themes.Viessmann) && (
							<Card>
								<CardBody>
									<SubTitle
										title={
											crud?.sub_titles?.gas_safe ||
											'GasSafe' +
												' ' +
												crud?.sub_titles?.details ||
											'Details'
										}
										className="mb-3 w-100"
										style={{ color: '#464C5E' }}
									/>
									<GasSafeLookup
										fetchOnLoad={isEdit}
										displayCard
										onGasSafeNumberChange={(v) => {
											setValues({
												gas_safe_licence_card_number: v,
											});
										}}
										onGasSafeConfirmedChange={(v) => {
											setValues({
												gas_safe_confirmed: v,
											});
										}}
										disabled={disabled}
										gasSafeNumber={
											store?.gas_safe_licence_card_number
										}
										gasSafeConfirmed={
											store?.gas_safe_confirmed
										}
										labels={{
											gasSafeNumber:
												fields?.gas_safe_licence_card_number,
											gasSafeConfirmed:
												fields?.gas_safe_confirmation_generic,
										}}
										errors={{
											gasSafeNumber:
												errors?.gas_safe_licence_card_number ||
												'',
											gasSafeConfirmed:
												errors?.gas_safe_confirmation ||
												'',
										}}
										type={'engineer'}
									/>
								</CardBody>
							</Card>
						)}
					</>
				)}
			</WidthConstrainedContainer>
			<BottomAnchoredControls>
				<Button
					label={crud?.buttons.back.default || 'Back'}
					onClick={() =>
						isEdit ? navigate(`/user/${userId}`) : navigate('/user')
					}
					variant={Variants.Dark}
					type={ButtonTypes.Outline}
					className={'me-4'}
					disabled={disabled}
				/>
				<Submit
					defaultLabel={
						isEdit
							? crud?.buttons.edit.default || 'Update'
							: crud?.buttons.create.default || 'Create'
					}
					loadingLabel={
						isEdit
							? crud?.buttons.edit.submitting || 'Updating...'
							: crud?.buttons.create.submitting || 'Creating...'
					}
					variant={Variants.Info}
				/>
			</BottomAnchoredControls>
		</PageLayout>
	);
}

export default UserForm;
