import {
	API_ENDPOINTS,
	DataTable,
	formatDate,
	FormInput,
	Icon,
	Icons,
	customToast,
	Modal,
	PageLayout,
	route,
	useLangContext,
	replaceKeyWithValue,
	StringHelpers,
	useAuthorisationContext,
	getThemeFromUrl,
	Themes,
	useDisabledContext,
	Variants,
} from "carrier-fe";
import { useSearchParams, useNavigate } from "react-router-dom";
import { useMemo, useState, useEffect, useCallback } from "react";
import { UserType } from "../../types/user";
import axios from "axios";

function UserList() {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const { fields, crud, models } = useLangContext();
	const { hasPolicyAccess } = useAuthorisationContext();
	const { disabled } = useDisabledContext();
	const { hasRoleAccess } = useAuthorisationContext();
	const [selectedItems, setSelectedItems] = useState<string[]>([]);
	const [exportModalOpen, setExportModalOpen] = useState(false);
	const [exporting, setExporting] = useState<boolean>(false);

	useEffect(() => {
		if (exporting) {
			handleExport();
		}
	}, [exporting]);
	
	const handleExport = async () => {
		try {
			let response = await axios.post(
				route(API_ENDPOINTS.ADMIN.USER.EXPORT),
				{ user_ids: selectedItems },
				{ responseType: "blob" }
			);

			let filename =
				response.headers["content-disposition"]
					?.split("filename=")[1]
					?.replace(/["']/g, "") ||
				`${new Date().toISOString().split("T")[0]}-user-export.csv`;

			const url = window.URL.createObjectURL(new Blob([response.data]));
			const link = document.createElement("a");
			link.href = url;
			link.setAttribute("download", filename);
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);

			customToast({
				title: response.data?.message || "Export Users as CSV successfully!",
				variant: Variants.Success,
			});
		} catch (error: any) {
			customToast({
				title:
					error?.response?.data?.message ||
					"An error occurred. Please try again.",
				variant: Variants.Danger,
			});
		} finally {
			setExporting(false);
			setExportModalOpen(false);
		}
	};

	const title = useMemo(
		() =>
			StringHelpers.title(
				replaceKeyWithValue(
					crud?.pages?.index.user || ":model List ",
					"model",
					crud?.models?.user || "User"
				)
			),
		[crud, models]
	);

	const handleOptionSelect = (action: any, data: UserType) => {
		const { id = "" } = data;

		const actions: Record<string, () => void> = {
			view: () => navigate(`/user/${id}`),
		};

		if (typeof action === "string" && actions[action]) {
			actions[action]();
		}
	};

	const items = useMemo(() => {
		const i = [];

		if (hasPolicyAccess({ name: "user", method: "create" })) {
			i.push({
				label: StringHelpers.title(crud.buttons.create.default || "Create"),
				disabled: disabled,
				href: "/user/create",
				icon: <Icon icon={Icons.ADD} />,
			});
		}

		if (
			getThemeFromUrl() === Themes.Viessmann &&
			hasPolicyAccess({ name: "user", method: "create" })
		) {
			i.push({
				label: StringHelpers.title(
					crud.buttons.viessmann_import?.default ||
					"Import Viessmann User Export"
				),
				disabled: disabled,
				onclick: () => () => setExportModalOpen(true),
				icon: <Icon icon={Icons.OPEN} />,
			});
		}

		i.push({
			label: StringHelpers.title(crud.buttons.export?.default || "Export"),
			disabled: disabled,
			onClick: () => {
				if (selectedItems.length === 0) {
					customToast({
						title: crud.buttons.export.select_users_to_export || "Please select users to export",
						variant: Variants.Warning,
					});
					setExportModalOpen(false);
					return;
				}
				setExportModalOpen(true);
			},
			icon: <Icon icon={Icons.DOWNLOAD} />,
		});

		return i;
	}, [selectedItems]);

	const colMapping = (data: UserType) => {
		const cols: any = [
			{
				id: "name",
				label: StringHelpers.title(fields?.name || "Name"),
				value: StringHelpers.title(data.name || "-"),
				sortable: true,
			},
			{
				id: "company_name",
				label: StringHelpers.title(fields?.company || "Company"),
				value: StringHelpers.title(data.company_name || "-"),
				sortable: true,
				visibleCondition: () =>
					hasRoleAccess(["super_admin"]) ||
					hasRoleAccess(["platform_manager"]) ||
					hasRoleAccess(["distribution_manager"]),
			},
			{
				id: "email",
				label: StringHelpers.title(fields?.email || "Email"),
				value: data.email || "-",
				sortable: true,
			},
			{
				id: "role_name",
				label: StringHelpers.title(fields?.roles || "Role"),
				value: StringHelpers.title(data.role_name_display || "-"),
				sortable: true,
				visibleCondition: () =>
					hasRoleAccess("super_admin") ||
					hasRoleAccess("distribution_manager") ||
					hasRoleAccess("platform_manager") ||
					hasRoleAccess("company_manager") ||
					hasRoleAccess("customer_manager"),
			},
			{
				id: "created_at",
				label: StringHelpers.title(fields?.created || "Created"),
				value: formatDate(data.created_at) || "-",
				sortable: true,
			},
			{
				id: "deleted_at",
				label: StringHelpers.title(fields?.archived || "Archived"),
				value: formatDate(data.deleted_at) || "-",
				sortable: true,
				visibleCondition: () => !!searchParams.get("filter[trashed]"),
			},
			{
				id: "actions",
				label: StringHelpers.title(fields?.actions || "Actions"),
				value: data.permissions.view ? (
					<FormInput
						type="select"
						label={StringHelpers.title(fields?.actions || "Action")}
						placeholder={StringHelpers.title(fields?.select || "Select")}
						name="actions"
						value=""
						disabled={disabled}
						onChange={(value) => {
							if (!!value && typeof value === "string") {
								handleOptionSelect(value, data);
							}
						}}
						options={[
							{
								label: StringHelpers.title(crud?.view || "View"),
								value: "view",
							},
						]}
						noMargin
					/>
				) : null,
			},
		];

		return cols;
	};

	return (
		<PageLayout title={title || "User Management"}>
			<DataTable<UserType>
				routeEndpoint={route(API_ENDPOINTS.ADMIN.USER.INDEX)}
				colMapping={colMapping}
				items={items}
				incrementPaginationButtons
				canViewArchived
				onSelect={(selected) => {
					const selectedUserIds = (selected as (string | number)[])
						.map((item) => item?.toString())
						.filter((id): id is string => id !== undefined);
					setSelectedItems(selectedUserIds);
				}}
				selectable
				variant={Variants.Info}
			/>
			<Modal
				open={exportModalOpen}
				onClose={() => setExportModalOpen(false)}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.export?.title || 'Export :name?',
						'name',
						crud?.models?.user || 'Users'
					)
				)}
				confirmText={exporting ? crud.buttons.export?.submitting : crud.buttons.export?.default}
				closeText={crud?.buttons.cancel.default || "Cancel"}
				onConfirm={() => setExporting(true)}
				disabled={disabled || exporting}
				variant={Variants.Danger}
			>
				{crud?.modals?.export?.description.map(
					(item: string, key: number) => {
						return (
							<p key={key}>
								{replaceKeyWithValue(
									item,
									'name',
									crud?.models?.users || 'Users'
								)}
							</p>
						);
					}
				)}
				<div className="row row-cols-lg-1">
					<div className="d-flex flex-column mb-3">
						<p>
							<strong>{StringHelpers.title(crud?.buttons?.export.selected_users || "Selected Users")}: </strong>
							{selectedItems.length}
						</p>
					</div>
				</div>
			</Modal>
		</PageLayout>
	);
}

export default UserList;
