import {
	Button,
	ButtonTypes,
	Card,
	CardBody,
	Dropdown,
	FormInput,
	Icons,
	Modal,
	replaceKeyWithValue,
	Sizes,
	StringHelpers,
	SubTitle,
	useDisabledContext,
	useLangContext,
	Variants,
} from 'carrier-fe';
import { QuestionType } from '../../../../types/quiz';
import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

type Props = {
	store: { questions: QuestionType[] };
	setValues: (values: { questions: QuestionType[] }) => void;
	errors: any;
};

function Questions({ store, setValues, errors }: Props) {
	const { crud, fields } = useLangContext();

	const { disabled } = useDisabledContext();

	const { quizId } = useParams();
	const isEdit = quizId ? true : false;
	const order = 0;

	const [deleteModalOpen, setDeleteModalOpen] = useState(false);
	const [questionToDelete, setQuestionToDelete] = useState<number | null>(
		null
	);
	const [deleteOptionModalOpen, setDeleteOptionModalOpen] = useState(false);
	const [optionToDelete, setOptionToDelete] = useState<{
		questionIndex: number;
		optionIndex: number;
		optionText: string;
	} | null>(null);

	useEffect(() => {
		if (!isEdit) {
			setValues({ questions: [{
			question: '',
			answer: '',
			options: [''],
			order: 0,
		}] });
		}
	},[isEdit])

	const confirmDeleteQuestion = (index: number) => {
		setQuestionToDelete(index);
		setDeleteModalOpen(true);
	};

	// Handle input change for question, answer, or options
	const handleChange = (
		index: number,
		field: string,
		value: string | string[],
		subIndex?: number
	) => {
		const updatedArray = [...store.questions];
		if (field === 'options' && typeof subIndex === 'number') {
			// Update specific option
			updatedArray[index].options[subIndex] = value as string;
		} else {
			// Update question or answer
			updatedArray[index][field] = value;
		}
		setValues({
			questions: updatedArray,
		});
	};

	// Add a new question to the array
	const addItem = () => {
		setValues({
			questions: [
				...store.questions,
				{ question: '', answer: '', options: [''], order: order + 1 },
			],
		});
	};

	// Remove a specific question
	const removeQuestion = (index: number) => {
		const updatedArray = [...store.questions];
		updatedArray.splice(index, 1); // Remove question by index
		setValues({
			questions: updatedArray,
		});
		setDeleteModalOpen(false);
		setQuestionToDelete(null);
	};

	const confirmDeleteOption = (
		questionIndex: number,
		optionIndex: number,
		optionText: string
	) => {
		if (!optionText || optionText.trim() === '') {
			removeOption(questionIndex, optionIndex);
		} else {
			setOptionToDelete({ questionIndex, optionIndex, optionText });
			setDeleteOptionModalOpen(true);
		}
	};

	// Add a new option to a specific question
	const addOption = (index: number) => {
		const updatedArray = [...store.questions];
		updatedArray[index].options.push(''); // Add empty option
		setValues({
			questions: updatedArray,
		});
	};

	// Remove an option from a specific question
	const removeOption = (questionIndex: number, optionIndex: number) => {
		const updatedArray = [...store.questions];
		if (updatedArray[questionIndex].options.length > 1) {
			updatedArray[questionIndex].options.splice(optionIndex, 1);
			setValues({
				questions: updatedArray,
			});
			setDeleteOptionModalOpen(false);
			setOptionToDelete(null);
		}
	};

	return (
		<>
			<Card>
				<CardBody>
					<SubTitle
						title={StringHelpers.title(
							crud?.sub_titles?.questions || 'Questions'
						)}
						className="mb-3"
						style={{ color: '#464C5E' }}
					/>

					{store.questions.map((item: any, index: any) => (
						<Card
							key={index}
							className="mb-3"
						>
							<CardBody>
								<div className="d-flex justify-content-between align-items-center mb-4">
									<h5 className="mb-0">
										{StringHelpers.title(fields?.question)}{' '}
										{index + 1}
									</h5>
									{store.questions.length > 1 && (
										<Dropdown
											type={ButtonTypes.Solid}
											variant={Variants.Dark}
											label={StringHelpers.title(
												crud?.buttons.actions.default ||
													'Actions'
											)}
											items={[
												{
													label: StringHelpers.title(
														crud?.buttons?.remove
															?.default ||
															'Remove'
													),
													icon: Icons.DELETE,
													variant: Variants.Danger,
													onClick: () =>
														confirmDeleteQuestion(
															index
														),
												},
											]}
										/>
									)}
								</div>
								<FormInput
									type="text"
									label={StringHelpers.title(
										fields?.question || 'Question'
									)}
									name="question"
									value={item.question}
									onChange={(value) =>
										handleChange(
											index,
											'question',
											value as string
										)
									}
									errorMessages={
										!!errors &&
										!!errors[`questions.${index}.question`]
											? errors[
													`questions.${index}.question`
											  ]
											: null
									}
								/>
								<FormInput
									type="text"
									label={StringHelpers.title(
										fields?.answer || 'Answer'
									)}
									name="answer"
									value={item.answer}
									onChange={(value) =>
										handleChange(
											index,
											'answer',
											value as string
										)
									}
									errorMessages={
										!!errors &&
										!!errors[`questions.${index}.answer`]
											? errors[
													`questions.${index}.answer`
											  ]
											: null
									}
								/>
								<div>
									<Card
										style={
											!!errors &&
											!!errors[
												`questions.${index}.options`
											]
												? { border: '1px solid red' }
												: {}
										}
										noMargin
									>
										<CardBody>
											<h6 className="mb-3">
												{StringHelpers.title(
													fields?.options || 'Options'
												)}
											</h6>
											{item.options.map(
												(
													option: string,
													optIndex: number
												) => (
													<div className="d-flex mb-4">
														<div className="flex-fill">
															<FormInput
																key={String(
																	optIndex
																)}
																type="text"
																name={`option-${optIndex}`}
																value={option}
																placeholder={StringHelpers.title(
																	fields?.option ||
																		'Option'
																)}
																onChange={(
																	value
																) =>
																	handleChange(
																		index,
																		'options',
																		value as string,
																		optIndex
																	)
																}
																errorMessages={
																	!!errors &&
																	!!errors[
																		`questions.${index}.options.${optIndex}`
																	]
																		? errors[
																				`questions.${index}.options.${optIndex}`
																		  ]
																		: null
																}
																noMargin
															/>
														</div>
														{item.options.length >
															1 && (
															<Button
																label={StringHelpers.title(
																	crud
																		?.buttons
																		?.remove
																		?.default ||
																		'Remove'
																)}
																type={
																	ButtonTypes.Outline
																}
																variant={
																	Variants.Danger
																}
																className="ms-4 align-self-start"
																onClick={() =>
																	confirmDeleteOption(
																		index,
																		optIndex,
																		option
																	)
																}
															/>
														)}
													</div>
												)
											)}
											<div className="d-flex justify-content-end mt-4 mb-1">
												<Button
													label={
														StringHelpers.title(
															crud?.buttons?.add
																?.default ||
																'Add'
														) +
														' ' +
														StringHelpers.title(
															fields?.option ||
																'Option'
														)
													}
													variant={Variants.Dark}
													type={ButtonTypes.Outline}
													onClick={() =>
														addOption(index)
													}
													size={Sizes.Small}
													disabled={disabled}
												/>
											</div>
										</CardBody>
									</Card>
									{!!errors &&
									!!errors[`questions.${index}.options`] ? (
										<div className="invalid-feedback d-block">
											{
												errors[
													`questions.${index}.options`
												]
											}
										</div>
									) : null}
								</div>
							</CardBody>
						</Card>
					))}
					<div className="d-flex justify-content-end mb-5">
						<Button
							label={
								StringHelpers.title(
									crud?.buttons?.add?.default || 'Add'
								) +
								' ' +
								StringHelpers.title(
									fields?.question || 'Question'
								)
							}
							type={ButtonTypes.Outline}
							onClick={addItem}
							variant={Variants.Dark}
							disabled={disabled}
						/>
					</div>
				</CardBody>
			</Card>

			<Modal
				open={deleteModalOpen}
				onClose={() => {
					setDeleteModalOpen(false);
					setQuestionToDelete(null);
				}}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.delete.title || 'Delete Question?',
						'name',
						StringHelpers.title(fields?.question)
					)
				)}
				confirmText={crud?.buttons.delete.default || 'Delete'}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => {
					if (questionToDelete !== null) {
						removeQuestion(questionToDelete);
					}
				}}
				variant={Variants.Danger}
			>
				{crud?.modals?.delete.description?.map(
					(item: string, key: number) => (
						<p key={key}>
							{replaceKeyWithValue(
								item,
								'name',
								StringHelpers.title(fields?.question) +
									' ' +
									((questionToDelete || 0) + 1)
							)}
						</p>
					)
				)}
			</Modal>

			<Modal
				open={deleteOptionModalOpen}
				onClose={() => {
					setDeleteOptionModalOpen(false);
					setOptionToDelete(null);
				}}
				title={StringHelpers.title(
					replaceKeyWithValue(
						crud?.modals?.delete.title || 'Delete Option?',
						'name',
						fields.options.slice(0, -1)
					)
				)}
				confirmText={crud?.buttons.delete.default || 'Delete'}
				closeText={crud?.buttons.cancel.default || 'Cancel'}
				onConfirm={() => {
					if (optionToDelete !== null) {
						removeOption(
							optionToDelete.questionIndex,
							optionToDelete.optionIndex
						);
					}
				}}
				variant={Variants.Danger}
			>
				{crud?.modals?.delete.description?.map(
					(item: string, key: number) => (
						<p key={key}>
							{replaceKeyWithValue(
								item,
								'name',
								`${StringHelpers.title(
									fields?.option || 'Option'
								)}: ${
									"'" + optionToDelete?.optionText + "'" || ''
								}`
							)}
						</p>
					)
				)}
			</Modal>
		</>
	);
}

export default Questions;
