import {
	API_ENDPOINTS,
	DataTable,
	DateFormats,
	route,
	StringHelpers,
	useLangContext,
} from 'carrier-fe';
import { format, parse } from 'date-fns';
import { useParams } from 'react-router-dom';

type AccessLogType = {
	id: string;
	user_id: string;
	ip_address: string;
	user_agent: string;
	created_at: string;
	updated_at: string;
	permissions: {
		view: boolean;
		update: boolean;
		delete: boolean;
		restore: boolean;
	};
};

function AccessLogs() {
	const { fields } = useLangContext();
	const { userId } = useParams();

	const colMapping = (data: AccessLogType) => [
		{
			id: 'ip_address',
			label: fields?.access_logs?.ip_address || 'IP Address',
			value: data.ip_address,
			sortable: true,
		},
		{
			id: 'created_at',
			label: fields?.access_logs?.created_at ?? 'Date & Time',
			value: format(
				parse(data.created_at, DateFormats.DB, new Date()),
				DateFormats.DATETIME_SHORT
			),
			sortable: true,
		},
	];

	return (
		<DataTable<AccessLogType>
			title={StringHelpers.title(
				fields?.access_logs?.title || 'Access logs'
			)}
			routeEndpoint={route(API_ENDPOINTS.ADMIN.USER.ACCESS_LOG, {
				userId: String(userId),
			})}
			colMapping={colMapping}
			incrementPaginationButtons={true}
			prefix='user_view_access_logs'
		/>
	);
}

export default AccessLogs;
