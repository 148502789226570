import {
   API_ENDPOINTS,
   DataTable,
   DateFormats,
   formatDateFns,
   FormInput,
   getThemeFromUrl,
   Icon,
   Icons,
   customToast,
 	Modal,
   PageLayout,
   Permissions,
   replaceKeyWithValue,
   Roles,
   route,
   StringHelpers,
   Themes,
   useAuthorisationContext,
   useLangContext,
   useUserContext,
   Variants,
} from 'carrier-fe';
import { ComponentProps, useCallback, useMemo, useState,useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { CompanyIndex } from '../../types/companies';
import axios from "axios"

type DataTableProps = ComponentProps<typeof DataTable>;

type DataColumns = ReturnType<DataTableProps['colMapping']>;

function CompaniesList() {
   const [refreshOnChange] = useState(new Date().toISOString());
   const navigate = useNavigate();
   const [searchParams] = useSearchParams();
   const { crud, fields, models } = useLangContext();
   const { hasPermissionAccess } = useAuthorisationContext();
   const { user } = useUserContext();
   const [selected, setSelected] = useState<string[]>([]);
   const [exportModalOpen, setExportModalOpen] = useState(false);
   const [exporting, setExporting] = useState<boolean>(false);

   const handleOptionSelect = (action: any, data: CompanyIndex) => {
      const { id = '' } = data;

      const actions: { [key: string]: () => void } = {
         view: () => navigate(`/companies/${id}`),
      };

      if (typeof action === 'string' && actions[action]) {
         actions[action]();
      }
   };

   useEffect(() => {
      if (exporting) {
         handleExport();
      }
   }, [exporting]);

   const handleExport = async () => {
      try {
         let response = await axios.post(
            route(API_ENDPOINTS.ADMIN.COMPANY.EXPORT),
            { company_ids: selected },
            { responseType: "blob" }
         );

         let filename =
            response.headers["content-disposition"]
               ?.split("filename=")[1]
               ?.replace(/["']/g, "") ||
            `${new Date().toISOString().split("T")[0]}-company-export.csv`;

         const url = window.URL.createObjectURL(new Blob([response.data]));
         const link = document.createElement("a");
         link.href = url;
         link.setAttribute("download", filename);
         document.body.appendChild(link);
         link.click();
         document.body.removeChild(link);
        
         customToast({
            title: response.data?.message || "Export Companies as CSV successfully!",
            variant: Variants.Success,
         });
      } catch (error: any) {
         customToast({
            title:
               error?.response?.data?.message ||
               "An error occurred. Please try again.",
            variant: Variants.Danger,
         });
      } finally {
         setExporting(false);
         setExportModalOpen(false);
      }
   };

   const dropdownItems = useMemo(() => {
      const items = [];
      if (hasPermissionAccess(Permissions.ADMIN_COMPANY_STORE)) {
         items.push({
            label: StringHelpers.title(crud?.buttons?.create?.default || 'Create'),
            disabled: false,
            onClick: () => navigate('/companies/create'),
            icon: <Icon icon={Icons.ADD} />,
         });
      }
      if (hasPermissionAccess(Permissions.ADMIN_COMPANY_BULK_EXPORT)) {
         items.push({
            label: StringHelpers.title(
               replaceKeyWithValue(
                  crud?.buttons?.export?.dynamic?.default || 'Export :any',
                  'any',
                  crud?.models?.companies || 'Companies'
               )
            ),
            disabled: false,
            onClick: () => {
               if (selected.length === 0) {
                  customToast({
                     title: crud.buttons.export.select_companies_to_export ,
                     variant: Variants.Warning,
                  });
                  setExportModalOpen(false);
                  return;
               }
               setExportModalOpen(true);
               },
            icon: <Icon icon={Icons.DOWNLOAD} />,
         });
      }
      return items;
   }, [crud, handleExport, hasPermissionAccess, navigate,selected]);

   const colMapping = (data: CompanyIndex) => {
      const cols: DataColumns = [
         {
            id: 'name',
            label: StringHelpers.title(crud?.table_columns?.name || 'Name'),
            value: StringHelpers.title(data.name || '-'),
            sortable: true,
         },
         {
            id: 'type',
            label: StringHelpers.title(crud?.table_columns?.type || 'Type'),
            value: StringHelpers.title(data.type || '-'),
            sortable: true,
            visibleCondition: () => user?.role_name !== Roles.DISTRIBUTION_MANAGER,
         },
         {
            id: 'contact_name',
            label: StringHelpers.title(crud?.table_columns?.contact || 'Contact'),
            value: StringHelpers.title(data.contact_name || '-'),
            sortable: true,
         },
         {
            id: 'registration_status',
            label: StringHelpers.title(
               crud?.table_columns?.registration_status || 'Registration Status'
            ),
            value: StringHelpers.title(data.registration_status || '-'),
            sortable: true,
         },
         {
            id: 'fgas_refcom_standard_number',
            label: crud?.table_columns?.fgas_number || 'FGAS Number',
            value: data.fgas_refcom_standard_number || '-',
            visibleCondition: () => getThemeFromUrl() === Themes.Toshiba,
            sortable: true,
         },
         {
            id: 'created_at',
            label: StringHelpers.title(fields?.created_at || 'Created At'),
            value: formatDateFns(data.created_at, DateFormats.DATETIME_SHORT),
            sortable: true,
         },
         {
            id: 'deleted_at',
            label: StringHelpers.title(fields?.archived_at || 'Archived At'),
            value: formatDateFns(data.deleted_at, DateFormats.DATETIME_SHORT),
            sortable: true,
            visibleCondition: () => !!data.deleted_at
         },
         {
            id: 'actions',
            label: StringHelpers.title(fields?.actions || 'Actions'),
            value: data.permissions.view ? (
               <FormInput
                  type="select"
                  label={StringHelpers.title(fields?.actions || 'Action')}
                  placeholder={StringHelpers.title(fields?.select || 'Select')}
                  name="actions"
                  value=""
                  onChange={(value) => {
                     if (!!value && typeof value === 'string') {
                        handleOptionSelect(value, data);
                     }
                  }}
                  options={[
                     {
                        label: StringHelpers.title(crud?.view || 'View'),
                        value: 'view',
                     },
                  ]}
                  noMargin
               />
            ) : null,
         },
      ];

      return cols;
   };

   const title = useMemo(
      () =>
         StringHelpers.title(
            replaceKeyWithValue(
               crud?.pages?.index?.title || ':model List ',
               'model',
               crud?.models?.companies || 'Companies'
            )
         ),
      [crud]
   );

   return (
      <PageLayout title={title}>
         <DataTable<CompanyIndex>
            routeEndpoint={route(API_ENDPOINTS.ADMIN.COMPANY.INDEX)}
            colMapping={colMapping}
            items={dropdownItems}
            incrementPaginationButtons
            refreshOnChange={refreshOnChange}
            canViewArchived
            selectable
            onSelect={(selected) => {
               const selectedCompanyIds = (selected as (string | number)[])
                  .map((item) => item?.toString())
                  .filter((id): id is string => id !== undefined);
               setSelected(selectedCompanyIds);
            }}
            variant={Variants.Info}
         />
         <Modal
            open={exportModalOpen}
            onClose={() => setExportModalOpen(false)}
            title={StringHelpers.title(
               replaceKeyWithValue(
                  crud?.models?.companies.export || "Export Companies",
                  "name",
                  crud?.models?.companies || "Companies"
               )
            )}
            confirmText={exporting ? crud.buttons.export?.submitting : crud.buttons.export?.default}
            closeText={crud?.buttons.cancel.default || "Cancel"}
            onConfirm={() => setExporting(true)}
            variant={Variants.Danger}
         >
            {crud?.modals?.export?.description.map(
               (item: string, key: number) => {
                  return (
                     <p key={key}>
                        {replaceKeyWithValue(
                           item,
                           'name',
                           crud?.models?.companies || 'Companies'
                        )}
                     </p>
                  );
               }
            )}
            <div className="d-flex flex-column mb-3">
               <p>
                  <strong>{StringHelpers.title(crud?.buttons?.export.selected_companies || "Selected Companies")}: </strong>
                  {selected.length}
               </p>
            </div>
         </Modal>
      </PageLayout>
   );
}

export default CompaniesList;
